<template>
  <v-select
    v-bind="$attrs"
    :items="availableItems"
    :label="label"
    v-model="selected"
    :item-text="itemText"
    item-value="ID"
    :clearable="clearable"
    :rules="rules"
    :loading="loading"
  ></v-select>
</template>
<script>
  import { get, sync, commit, call } from 'vuex-pathify'
  export default {
    name: 'select-LuLicenseStatus',
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      label: {
        type: String,
        default: 'Lu License Status',
        },
      value: Number,
      clearable: {
        type: Boolean,
        default: false
      },
      rules: Array,
    },
    data(){
      return {
        itemText: 'Description',
      }
    },
    computed: {
      ...get('luLicenseStatus',['luLicenseStatus', 'loading']),
      availableItems() {
          //if you have a property (like an IsActive boolean) you can change the true to filer i.e. i.IsActive
          var list = this.luLicenseStatus.filter((i) => true )
          list = list.filter((i) => i.IsActive )
          // if existing record make sure the previous selection is available even if no longer active
          if (this.value > 0) {
            var selectedItem = this.luLicenseStatus.find((i) => i.ID == this.value)
            if (!list.find((i) => i.ID == this.value) && selectedItem) {
              list.push(selectedItem)
            }
          }
          // sort by column
          list.sort((a, b) => {
            //set the property to sort on
            let textField = this.itemText
            let sort = a[textField] > b[textField] ? 1 : -1
            return sort
          })
          return list
      },
      selected: {
        get() {
          if (this.value) {
            return this.value
          } else {
            return null
          }
        },
        set(newValue) {
          this.$emit('change', newValue)
        }
      }
    },
    created(){
      this.loadLuLicenseStatus()
    },
    methods: {
      loadLuLicenseStatus: call('luLicenseStatus/loadLuLicenseStatus'),
    },
  }
</script>
<style>

</style>