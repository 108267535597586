<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <HempForm></HempForm>
        <licenseNotes></licenseNotes>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import HempForm from '@components/licenses/hemp-applications/hempForm'
import licenseNotes from '@components/agriculture-main/admin/licenseNotes'
export default {
  page: {
    title: 'HempApplication',
  },
  components: {
    HempForm,
    licenseNotes,
  },
  data() {
    return {}
  },
  created() {},
  computed: {
  },
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
