<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="primary headline text-left white--text">
                <div>
                  License Documents
                  <v-btn
                    color="white"
                    class="primary--text ml-4"
                    small
                    @click.native.stop="addHempDocs"
                  >Add Document</v-btn>

                  <v-btn
                    v-if="isInternalUser"
                    color="white"
                    class="primary--text ml-4"
                    small
                    @click.native.stop="openGenerateModal"
                  >Generate License</v-btn>
                </div>
              </v-expansion-panel-header>
              <!-- <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click.stop="addHempDocs"
                      fab
                      depressed
                      x-small
                      max-width="34px"
                    >
                      <v-icon color="primary" dark>mdi-text-box-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add Documentation</span>
                </v-tooltip>
                <span class="pl-2">License Documentation</span>
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>-->
              <v-expansion-panel-content>
                <template>
                  <v-data-table
                    :items="mappedData"
                    :search="searchText"
                    :headers="filteredHeaders"
                    class="pt-4"
                    dense
                  >
                    <template v-slot:item.download="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="downloadDocument(item)"
                          >
                            <v-icon class="clickable" color="primary">mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>Download Document</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.insertDate="{ item }">
                      {{
                      item.insertDate | dateformat
                      }}
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="addDocsDialog" @keydown.esc="addDocsDialog = false" width="750">
      <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="primary headline text-left white--text">Add Document</h3>
            <v-tooltip max-width="250px" right z-index="1000">
              <template v-slot:activator="{ on }">
                <v-btn text icon v-on="on">
                  <v-icon class="white--text pl-5">mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <span>Supporting documents include but not limited to the following: Photo ID, Certificate of Good standing, Maps of licensed area, acknowledgement forms, criminal history report (this can be mailed in as well), list of products being produced, harvest reports, etc.</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-col sm="12" md="6" class="pr-1">
              <v-select
                label="Document Type"
                v-model="selectedHempDocument.Document.DocumentTypeID"
                :items="documentTypes"
                item-value="ID"
                item-text="Description"
                :rules="[v => !!v || 'required']"
              ></v-select>
            </v-col>
            <v-col
              sm="12"
              md="6"
              class="pr-1"
              v-if="selectedHempDocument.Document.DocumentTypeID == 4"
            >
              <v-select
                label="Associated Area"
                v-model="selectedHempDocument.HempLicenseAreaID"
                :items="availableAreas"
                :rules="[v => !!v || 'required']"
                item-value="ID"
                item-text="Name"
              ></v-select>
            </v-col>
            <div class="dropbox">
              <input class="input-file" type="file" @change="getFiles" />
              <p
                v-if="uploadList.length == 0"
                class="extra-margin"
              >Drag file to upload or click to browse</p>
              <div v-if="uploadList.length > 0" class="extra-margin">
                <p class="uploadList" v-for="(item, index) in items" :key="index">{{ item.name }}</p>
              </div>
            </div>
            <v-card-actions>
              <v-btn @click="uploadDocument" :disabled="saving" color="primary">Save</v-btn>
              <v-btn @click="closeDialog" color="primary" outlined>Cancel</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      persistent
      v-model="generateLicenseDialog"
      @keydown.esc="generateLicenseDialog = false"
      width="750"
    >
      <v-form ref="form" @submit.prevent lazy-validation v-model="valid">
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="primary headline text-left white--text">Generate License</h3>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-col sm="12" md="6" class="pr-1">
              <BaseDatePickerWithText
                label="Date Paid"
                v-model="paidDate"
                :rules="[v => !!v || 'required']"
              ></BaseDatePickerWithText>
            </v-col>
            <v-card-actions>
              <v-btn @click="generateLicense" color="primary">Generate</v-btn>
              <v-btn @click="closeGenerateDialog" color="primary" outlined>Cancel</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import download from 'downloadjs'
import { get, sync, commit, call } from 'vuex-pathify'
import { authMethods, authComputed } from '@state/helpers'
import HempLicenseDocument from '@classes/HempLicenseDocument'
export default {
  page: {
    title: '',
  },
  //model: {
  //	prop: ''
  //},
  props: {
    hempLicenseID: {
      type: Number,
      required: true,
    },
    hempLicenseAreas: {
      type: Array,
    },
    documentTypes: {
      type: Array,
      required: true,
    },
    documentList: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      panel: [0, 1],
      searchText: '',
      valid: true,
      selectedHempDocument: new HempLicenseDocument(),
      addDocsDialog: false,
      saving: false,
      uploadList: [],
      items: [],
      generateLicenseDialog: false,
      paidDate: new Date().toISOString().substr(0, 10),
    }
  },
  created() {},
  computed: {
    ...authComputed,
    availableAreas() {
      if (this.hempLicenseAreas) {
        var list = this.hempLicenseAreas.filter((i) => i.HempOwnershipID == 1)
        // if existing record make sure the previous selection is available even if no longer active

        return list
      } else {
        return []
      }
    },
    mappedData() {
      var list = this.documentList.map((item) => {
        return {
          ...item,
          insertDate: item.Document.InsertDate,
          fileName: item.Document.FileName,
          documentType: item.Document.DocumentType.Description,
          area: item.HempLicenseArea ? item.HempLicenseArea.Name : '',
        }
      })
      return list
    },
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: '',
          align: 'center',
          sortable: true,
          value: 'download',
          visible: true,
        },
        {
          text: 'File Name',
          align: 'center',
          sortable: true,
          value: 'fileName',
          visible: true,
        },
        {
          text: 'Document Type',
          align: 'center',
          sortable: true,
          value: 'documentType',
          visible: true,
        },
        {
          text: 'Area',
          align: 'center',
          sortable: true,
          value: 'area',
          visible: true,
        },
        {
          text: 'Upload Date',
          align: 'center',
          sortable: true,
          value: 'insertDate',
          visible: true,
        },
        { text: '', value: 'data-table-expand', visible: true },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    async validate() {
      this.$refs.form.validate()
    },
    addHempDocs() {
      this.modalText = 'Insert Document'
      this.addDocsDialog = !this.addDocsDialog
    },
    closeDialog() {
      this.addDocsDialog = false
    },
    getFiles: function(event) {
      this.uploadList = []
      for (let file in event.target.files) {
        this.uploadList.push(event.target.files[file])
      }
      for (let i = 0; i < this.uploadList.length - 2; i++) {
        if (this.uploadList[i].name) this.items.push(this.uploadList[i])
      }
    },
    openGenerateModal() {
      this.generateLicenseDialog = true
    },
    generateLicense() {
      let url = `HempLicenseDocument/GenerateLicense/${this.hempLicenseID}/${this.paidDate}`
      let headers = { responseType: 'blob' }
      this.$axios.get(url, headers).then(
        (res) => {
          const content = res.headers['content-type']
          const dispo = res.headers['content-disposition']
          var filename = ''
          if (dispo && dispo.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(dispo)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }
          download(res.data, filename, content)
          this.generateLicenseDialog = false
          this.$emit('refresh')
        },
        (error) => {
          this.handleError(error)
          // this.error = error
          // this.displayError = true
          console.log(error)
        }
      )
    },
    closeGenerateDialog() {
      this.generateLicenseDialog = false
    },
    uploadDocument() {
      this.validate().then(() => {
        if (this.valid) {
          var self = this
          var docType = this.selectedHempDocument.Document.DocumentTypeID
          this.saving = true
          for (let i = 0; i < this.uploadList.length - 2; i++) {
            let file = this.uploadList[i]
            let fileReader = new FileReader()
            if (fileReader && file) {
              fileReader.readAsDataURL(file)
              fileReader.onload = function() {
                let contents = fileReader.result.split(',')[1]
                let obj = {
                  ID: 0,
                  HempLicenseID: self.hempLicenseID,
                  HempLicenseAreaID:
                    self.selectedHempDocument.HempLicenseAreaID,
                  DocumentID: 0,
                  Document: {
                    ID: 0,
                    FileName: file.name,
                    MimeType: file.type,
                    DocumentTypeID: docType,
                    DocumentFile: {
                      Image: contents,
                    },
                  },
                }
                axios
                  .post(`/HempLicenseDocument/`, obj)
                  .then(function(response) {
                    self.$emit('refresh')
                  })
                  .catch(function(error) {
                    console.log(error)
                    self.handleError(error)
                    // this.error = error
                    // this.displayError = true
                  })
              }
            }
          }
          this.saving = false
          this.addDocsDialog = false
        }
      })
    },
    downloadDocument(doc) {
      let url
      url = `HempLicenseDocument/Download/${doc.ID}`
      let headers = { responseType: 'blob' }
      this.$axios.get(url, headers).then(
        (res) => {
          const content = res.headers['content-type']
          const dispo = res.headers['content-disposition']
          var filename = ''
          if (dispo && dispo.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(dispo)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }
          download(res.data, filename, content)
        },
        (error) => {
          this.handleError(error)
          // this.error = error
          // this.displayError = true
          console.log(error)
        }
      )
    },
  },
  watch: {
    selectedHempDocument(nval, oval) {
      if (nval.Document.DocumentTypeID != 4) {
        this.selectedHempDocument.HempLicenseAreaID = null
      }
    },
  },
}
</script>
<style scoped>
.dropbox {
  outline: 2px dashed rgb(190, 190, 190); /* the dash box */
  outline-offset: -10px;
  background: #fafafa;
  color: dimgray;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
  /* width: 20%; */
  height: 100px;
  /* margin-left: auto;
  margin-right: auto;  */
  margin-top: 10px;
}

.input-file {
  outline: 2px solid black;
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
</style>