<template>
  <v-row>
    <v-col>
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title class="headline text-left white--text">
              Hemp Application
              <v-btn
                v-if="!isInternalUser"
                color="white"
                class="primary--text ml-4"
                small
                @click="openInstructions"
                >View Instructions</v-btn
              >
              <v-btn
                v-if="isInternalUser && currentLicenseStatus.ID > 0"
                color="white"
                class="primary--text ml-4"
                small
                @click="updateLicenseStatus"
                >{{ currentLicenseStatus.Description }}</v-btn
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-card-text class="headline">
          <v-row>
            <v-col sm="12">
              <div v-if="currentLicensePeriod.ID > 0">
                <div class="pb-1">
                  <span class="h4">License Fee:</span>
                  <span class="body-1 pl-2"
                    >${{ currentLicensePeriod.LicenseFee }}</span
                  >
                  <v-btn
                    v-if="isInternalUser && currentLicenseStatus.StatusID != 2"
                    small
                    color="primary"
                    icon
                    @click="editLicenseFee"
                  >
                    <v-icon class="clickable" color="primary"
                      >mdi-pencil</v-icon
                    >
                  </v-btn>
                </div>
                <div class="pb-1">
                  <span class="h4">Expiration Date:</span>
                  <span class="body-1 pl-2">
                    {{
                      moment(currentLicensePeriod.ExpirationDate).format('L')
                    }}
                  </span>
                </div>
              </div>
              <div v-if="currentLicenseStatus.ID > 0">
                <span class="h4">Status:</span>
                <span class="body-1 pl-2">
                  {{ currentLicenseStatus.Status.Description }}
                </span>
              </div>
              <div
                v-if="
                  currentLicenseStatus.ID > 0 || currentLicensePeriod.ID > 0
                "
                class="pb-4"
              >
                <v-divider class="secondary my-8"></v-divider>
              </div>
              <v-form
                ref="hempLicenseForm"
                @submit.prevent
                lazy-validation
                v-model="valid"
              >
                <v-row v-if="isInternalUser">
                  <v-col sm="6" md="2">
                    <v-checkbox
                      :disabled="!isAdmin"
                      label="Is Active"
                      v-model="selectedSetHempLicense.License.IsActive"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    isInternalUser ||
                    selectedSetHempLicense.License.LicenseNumber
                  "
                >
                  <v-col sm="6" md="2">
                    <v-text-field
                      :disabled="!isAdmin"
                      label="License Number"
                      v-model="selectedSetHempLicense.License.LicenseNumber"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- HempLicense -->
                  <v-col sm="12" md="4" class="mt-n3">
                    <v-row>
                      <v-col sm="12" md="6">
                        <LuBusinessType
                          v-model="selectedSetHempLicense.BusinessTypeID"
                          label="Business Type"
                          :rules="[(v) => !!v || 'required']"
                          :disabled="!isInternalUser && !businessCanEditLicense"
                        ></LuBusinessType>
                      </v-col>
                      <v-col
                        sm="12"
                        md="6"
                        v-if="selectedSetHempLicense.BusinessTypeID == 5"
                      >
                        <v-text-field
                          :disabled="!isInternalUser && !businessCanEditLicense"
                          v-model="selectedSetHempLicense.Other"
                          label="Please Describe"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col sm="12" md="4" class="mt-1">
                    <LuLicenseSubType
                      :disabled="!isInternalUser && !businessCanEditLicense"
                      label="License Type"
                      v-model="selectedSetHempLicense.License.LicenseSubType"
                      selfKey="LicenseID"
                      :selfID="selectedSetHempLicense.License.ID"
                      relatedItemKey="LicenseSubTypeID"
                      joinItemName="LicenseSubType"
                      :rules="[(v) => !!v || 'required']"
                      :items="licenseSubTypes"
                    ></LuLicenseSubType>
                  </v-col>
                  <v-col sm="12" md="4">
                    <datePickerWithText
                      :disabled="!isInternalUser && !businessCanEditLicense"
                      label="Date Of Birth mm/dd/yyyy"
                      v-model="selectedSetHempLicense.DateOfBirth"
                      :max="new Date().toISOString().substr(0, 10)"
                      :rules="[(v) => !!v || 'required']"
                    ></datePickerWithText>
                  </v-col>
                </v-row>
                <v-row v-if="isInternalUser || businessCanEditLicense">
                  <v-col sm="12">
                    <v-btn color="primary" @click="saveHempLicenseData"
                      >Save</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
              <!-- HempLicenseAreas -->
              <v-card
                class="mx-auto mt-8 elevation-0"
                v-if="selectedHempLicense.ID > 0"
              >
                <v-expansion-panels v-model="panel" multiple>
                  <v-expansion-panel class="elevation-0">
                    <v-expansion-panel-header
                      class="primary headline text-left white--text"
                    >
                      <div>
                        Licensed Areas
                        <v-btn
                          v-if="isInternalUser || businessCanEditLicense"
                          color="white"
                          class="primary--text ml-4"
                          small
                          @click.native.stop="addLicensedArea"
                          >Add Licensed Area</v-btn
                        >
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :headers="filteredHeaders"
                        :items="selectedHempLicense.HempLicenseArea"
                        :search="searchText"
                        class="pt-4"
                        dense
                      >
                        <template v-slot:item.edit="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                color="primary"
                                v-on="on"
                                icon
                                @click="editHempLicenseArea(item)"
                              >
                                <v-icon
                                  v-if="
                                    isInternalUser || businessCanEditLicense
                                  "
                                  class="clickable"
                                  color="primary"
                                  >mdi-pencil</v-icon
                                >
                                <v-icon
                                  v-if="
                                    !isInternalUser && !businessCanEditLicense
                                  "
                                  class="clickable"
                                  color="primary"
                                  >mdi-eye</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Edit HempLicenseArea Information</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:item.delete="{ item }">
                          <v-tooltip v-if="item.ID > 0" bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                color="primary"
                                v-on="on"
                                icon
                                @click="deleteConfirm(item)"
                              >
                                <v-icon class="clickable" color="primary"
                                  >mdi-delete</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Delete HempLicenseArea</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
              <v-dialog persistent v-model="licensedAreasDialog" width="750">
                <v-card class="elevation-0">
                  <v-card-title class="primary">
                    <h3 class="primary headline text-left white--text">{{
                      modalText
                    }}</h3>
                  </v-card-title>
                  <v-card-text class="headline">
                    <v-row>
                      <v-col sm="12">
                        <v-form
                          ref="hempLicenseAreasForm"
                          @submit.prevent
                          lazy-validation
                          v-model="validLicenseArea"
                        >
                          <v-row>
                            <v-col sm="12" md="2" class="mt-1">
                              <LuHempOwnership
                                v-model="
                                  selectedHempLicenseArea.HempOwnershipID
                                "
                                :disabled="
                                  !isInternalUser && !businessCanEditLicense
                                "
                                label="Ownership"
                                :rules="[(v) => !!v || 'required']"
                              ></LuHempOwnership>
                            </v-col>
                            <v-col sm="12" md="4">
                              <v-text-field
                                v-model="selectedHempLicenseArea.Name"
                                label="Field/Building Name"
                                :rules="[(v) => !!v || 'required']"
                                :disabled="
                                  !isInternalUser && !businessCanEditLicense
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col sm="12" md="6">
                              <v-text-field
                                label="Legal Description"
                                v-model="
                                  selectedHempLicenseArea.LegalDescription
                                "
                                :rules="[(v) => !!v || 'required']"
                                :disabled="
                                  !isInternalUser && !businessCanEditLicense
                                "
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col sm="12" md="6">
                              <v-text-field
                                label="Latitude"
                                hint="i.e 43.0000"
                                v-model="selectedHempLicenseArea.Latitude"
                                :rules="[(v) => !!v || 'required']"
                                :disabled="
                                  !isInternalUser && !businessCanEditLicense
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col sm="12" md="6">
                              <v-text-field
                                v-model="selectedHempLicenseArea.Longitude"
                                hint="i.e -107.0000"
                                label="Longitude"
                                :rules="[(v) => !!v || 'required']"
                                :disabled="
                                  !isInternalUser && !businessCanEditLicense
                                "
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col sm="12" md="4">
                              <v-text-field
                                hint="please specify unit (acres, sqft etc.)"
                                v-model="selectedHempLicenseArea.ProducingArea"
                                label="Producing area size"
                                :disabled="
                                  !isInternalUser && !businessCanEditLicense
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col sm="12" md="4">
                              <v-text-field
                                hint="please specify unit (acres, sqft etc.)"
                                label="Processing area size"
                                v-model="selectedHempLicenseArea.ProcessingArea"
                                :disabled="
                                  !isInternalUser && !businessCanEditLicense
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col sm="12" md="4" class="mt-1">
                              <LuHempAreaType
                                v-model="selectedHempLicenseArea.AreaTypeID"
                                :disabled="
                                  !isInternalUser && !businessCanEditLicense
                                "
                                label="Area Type"
                                :rules="[(v) => !!v || 'required']"
                              ></LuHempAreaType>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-btn
                        v-if="isInternalUser || businessCanEditLicense"
                        color="primary"
                        @click="saveHempLicenseAreas"
                        >Save</v-btn
                      >
                      <v-btn color="primary" @click="closeDialog">Close</v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <!-- KeyParticipants -->
              <v-card
                class="mx-auto mt-8 elevation-0"
                v-if="selectedHempLicense.ID > 0"
              >
                <v-expansion-panels v-model="panel" multiple>
                  <v-expansion-panel class="elevation-0">
                    <v-expansion-panel-header
                      class="primary headline text-left white--text"
                    >
                      <div>
                        Key Participants
                        <v-btn
                          v-if="isInternalUser || businessCanEditLicense"
                          color="white"
                          class="primary--text ml-4"
                          small
                          @click.native.stop="addKeyParticipant"
                          >Add Key Participant</v-btn
                        >
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :headers="filteredHeadersParticipant"
                        :items="selectedHempLicense.KeyParticipants"
                        :search="searchText"
                        class="pt-4"
                        dense
                      >
                        <template v-slot:item.edit="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                color="primary"
                                v-on="on"
                                icon
                                @click="editKeyParticipant(item)"
                              >
                                <v-icon
                                  v-if="
                                    isInternalUser || businessCanEditLicense
                                  "
                                  class="clickable"
                                  color="primary"
                                  >mdi-pencil</v-icon
                                >
                                <v-icon
                                  v-if="
                                    !isInternalUser && !businessCanEditLicense
                                  "
                                  class="clickable"
                                  color="primary"
                                  >mdi-eye</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Edit Key Participant Information</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:item.delete="{ item }">
                          <v-tooltip v-if="item.ID > 0" bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                small
                                color="primary"
                                v-on="on"
                                icon
                                @click="deleteConfirm(item)"
                              >
                                <v-icon class="clickable" color="primary"
                                  >mdi-delete</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Delete Key Participant</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
              <v-dialog
                persistent
                v-model="licensedKeyParticipantsDialog"
                width="750"
              >
                <v-card class="elevation-0">
                  <v-card-title class="primary">
                    <h3 class="primary headline text-left white--text">{{
                      modalText
                    }}</h3>
                  </v-card-title>
                  <v-card-text class="headline">
                    <v-row>
                      <v-col sm="12">
                        <v-form
                          ref="hempKeyParticipantsForm"
                          @submit.prevent
                          lazy-validation
                          v-model="validKeyParticipants"
                        >
                          <v-row>
                            <v-col sm="12" md="6" class="mt-1">
                              <v-text-field
                                v-model="selectedKeyParticipants.Name"
                                label="Key Participant Name"
                                :rules="[(v) => !!v || 'required']"
                                :disabled="
                                  !isInternalUser && !businessCanEditLicense
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col sm="12" md="6">
                              <v-text-field
                                v-model="selectedKeyParticipants.Title"
                                label="Key Participant Title"
                                :rules="[(v) => !!v || 'required']"
                                :disabled="
                                  !isInternalUser && !businessCanEditLicense
                                "
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-btn
                        v-if="isInternalUser || businessCanEditLicense"
                        color="primary"
                        @click="saveKeyParticipantsList"
                        >Save</v-btn
                      >
                      <v-btn color="primary" @click="closeDialogKeyParticipant"
                        >Close</v-btn
                      >
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-card
                class="mx-auto mt-8 elevation-0"
                v-if="selectedHempLicense.ID > 0"
              >
                <div v-if="!validLicense && selectedHempLicense.ID > 0">
                  <v-list flat>
                    <v-list-item-group>
                      <v-list-item
                        inactive
                        v-for="(error, index) in validationErrors"
                        :key="index"
                      >
                        <v-list-item-icon>
                          <v-icon dense class="red--text"
                            >mdi-exclamation-thick</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="pa-0">
                            {{ error }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
                <hemp-license-documents
                  :hempLicenseID="selectedHempLicense.ID"
                  :documentTypes="licenseDocumentTypes"
                  :documentList="selectedHempLicense.HempLicenseDocument"
                  :hempLicenseAreas="selectedHempLicense.HempLicenseArea"
                  @refresh="refreshSelectedHempLicense"
                ></hemp-license-documents>
              </v-card>
            </v-col>

            <v-card-actions v-if="validLicense && !currentLicenseStatus.ID">
              <v-btn color="primary" @click="saveLicense">Submit</v-btn>
            </v-card-actions>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="dialog" width="950">
      <Instructions
        :closeInstructions="closeInstructions"
        v-on:closeInstructionsDialog="closeInstructionsDialog"
      ></Instructions>
    </v-dialog>
    <v-dialog v-model="licenseStatusDialog" width="400">
      <v-card class="elevation-0">
        <v-card-title class="primary">
          <h3 class="primary headline text-left white--text">{{
            modalText
          }}</h3>
        </v-card-title>
        <v-card-text class="headline">
          <v-row>
            <v-col sm="12">
              <v-form
                ref="hempLicenseStatusForm"
                @submit.prevent
                lazy-validation
                v-model="validLicenseStatus"
              >
                <v-row>
                  <v-col sm="12">
                    <LuLicenseStatus
                      v-model="selectedLicenseStatus.StatusID"
                      label="License Status"
                    ></LuLicenseStatus>
                  </v-col>
                </v-row>
                <v-row></v-row>
              </v-form>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn color="primary" @click="saveLicenseStatusUpdate">Save</v-btn>
            <v-btn color="primary" @click="licenseStatusUpdateClose"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="licenseFeeDialog" width="400">
      <v-card class="elevation-0">
        <v-card-title class="primary">
          <h3 class="primary headline text-left white--text"
            >Edit License Fee</h3
          >
        </v-card-title>
        <v-card-text class="headline">
          <v-row>
            <v-col sm="12">
              <v-form
                ref="licenseFeeForm"
                @submit.prevent
                lazy-validation
                v-model="validLicenseFee"
              >
                <v-row>
                  <v-col sm="12">
                    <v-text-field
                      label="New License Fee"
                      v-model="newLicenseFee"
                      :rules="[(v) => !!v || 'required']"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row></v-row>
              </v-form>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn color="primary" @click="saveLicenseFee">Save</v-btn>
            <v-btn color="primary" @click="licenseFeeDialogClose">Close</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import datePickerWithText from '@components/datePickerWithText'
import Instructions from '@components/agriculture-main/admin/instructions'
import { get, sync, commit, call } from 'vuex-pathify'
import { authMethods, authComputed } from '@state/helpers'
import moment from 'moment'
import HempLicense from '@classes/HempLicense'
import HempLicenseArea from '@classes/HempLicenseArea'
import KeyParticipants from '@classes/KeyParticipants'
import LicensePeriod from '@classes/LicensePeriod'
import LicenseStatus from '@classes/LicenseStatus'
import LuBusinessType from '@components/select/single/LuBusinessType'
import LuLicenseSubType from '@components/select/a-multi-join/LuLicenseSubType'
import LuHempOwnership from '@components/select/single/LuHempOwnership'
import LuHempAreaType from '@components/select/single/LuHempAreaType'
import LuLicenseStatus from '@components/select/single/LuLicenseStatus'
import hempLicenseDocuments from '@components/licenses/hemp-applications/hempLicenseDocuments'

export default {
  page: {
    title: 'HempApplication',
  },
  components: {
    datePickerWithText,
    Instructions,
    LuBusinessType,
    LuLicenseSubType,
    LuHempOwnership,
    LuLicenseStatus,
    LuHempAreaType,
    'hemp-license-documents': hempLicenseDocuments,
  },
  data() {
    return {
      valid: true,
      validLicenseArea: true,
      validKeyParticipants: true,
      validLicenseStatus: true,
      //validLicense: true,
      dialog: false,
      licenseStatusDialog: false,
      licensedAreasDialog: false,
      licensedKeyParticipantsDialog: false,
      modalText: '',
      searchText: '',
      panel: [0, 1],
      closeInstructions: true,
      selectedSetHempLicense: new HempLicense(),
      selectedHempLicenseArea: new HempLicenseArea(),
      selectedKeyParticipants: new KeyParticipants(),
      validationErrors: [],
      selectedLicenseStatus: new LicenseStatus(),
      moment: moment,
      licenseFeeDialog: false,
      validLicenseFee: true,
      newLicenseFee: 0,
    }
  },
  created() {
    this.loadLuLicenseType()
    var hempLicenseID = parseInt(this.$route.params.hempLicenseID)
    if (hempLicenseID > 0) {
      this.loadSelectedHempLicense(hempLicenseID)
    } else {
      this.setSelectedHempLicense(new HempLicense())
    }
    //this.loadHempLicenseArea()
  },
  computed: {
    ...authComputed,
    ...get('hempLicenseArea', ['saving']),
    ...get('keyParticipants', ['saving']),
    ...get('hempLicense', ['selectedHempLicense']),
    ...get('luLicenseType', ['luLicenseType']),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: false,
        },
        {
          text: 'Name',
          align: 'center',
          sortable: true,
          value: 'Name',
          visible: true,
        },
        {
          text: 'Legal Description',
          align: 'center',
          sortable: true,
          value: 'LegalDescription',
          visible: true,
        },
        {
          text: 'Latitude',
          align: 'center',
          sortable: true,
          value: 'Latitude',
          visible: true,
        },
        {
          text: 'Longitude',
          align: 'center',
          sortable: true,
          value: 'Longitude',
          visible: true,
        },
        {
          text: 'Producing Area',
          align: 'center',
          sortable: true,
          value: 'ProducingArea',
          visible: true,
        },
        {
          text: 'Processing Area',
          align: 'center',
          sortable: true,
          value: 'ProcessingArea',
          visible: true,
        },
        {
          text: 'Area Type',
          align: 'center',
          sortable: true,
          value: 'AreaType.Description',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    headersParticipant() {
      return [
        {
          text: '',
          align: 'left',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'Name',
          visible: true,
        },
        {
          text: 'Title',
          align: 'center',
          sortable: true,
          value: 'Title',
          visible: true,
        },
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    businessCanEditLicense() {
      if (!this.selectedHempLicense.ID > 0) {
        return true
      } else if (!this.currentLicenseStatus.ID) {
        return true
      } else if (
        this.currentLicensePeriod &&
        this.currentLicensePeriod.ExpirationDate
      ) {
        let expirationDate = moment(this.currentLicensePeriod.ExpirationDate)
        if (expirationDate < moment().add(2, 'months')) {
          return true
        } else {
          return false
        }
      }
      console.log(this.selectedHempLicense)
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    filteredHeadersParticipant() {
      return this.headersParticipant.filter((h) => h.visible)
    },
    availableOwnerships() {
      //if you have a property (like an IsActive boolean) you can change the true to filer i.e. i.IsActive
      var list = this.luHempOwnership.filter((i) => true)
      list = list.filter((i) => i.IsActive)
      // if existing record make sure the previous selection is available even if no longer active
      if (this.value > 0) {
        var selectedItem = this.luHempOwnership.find((i) => i.ID == this.value)
        if (!list.find((i) => i.ID == this.value) && selectedItem) {
          list.push(selectedItem)
        }
      }
      return list
    },
    hempLicenseType() {
      return this.luLicenseType.find((i) => i.ID == 1)
    },
    licenseSubTypes() {
      if (this.hempLicenseType && this.hempLicenseType.LuLicenseSubType) {
        return this.hempLicenseType.LuLicenseSubType
      } else {
        return []
      }
    },
    licenseDocumentTypes() {
      if (this.hempLicenseType && this.hempLicenseType.LicenseDocumentTypes) {
        var list = this.hempLicenseType.LicenseDocumentTypes.map(
          (item) => item.DocumentType
        )
        if (!this.isInternalUser) {
          list = list.filter((item) => !item.InternalOnly)
        }
        return list
      } else {
        return []
      }
    },
    currentLicensePeriod() {
      if (
        this.selectedHempLicense &&
        this.selectedHempLicense.License &&
        this.selectedHempLicense.License.LicensePeriod &&
        this.selectedHempLicense.License.LicensePeriod.length > 0
      ) {
        let period = this.selectedHempLicense.License.LicensePeriod.filter(
          (item) => {
            return true
          }
        ).sort((a, b) => {
          return b.ID - a.ID
        })
        if (period.length > 0) {
          return period[0]
        } else {
          return {}
        }
      } else return {}
    },
    currentLicenseStatus() {
      if (
        this.currentLicensePeriod &&
        this.currentLicensePeriod.LicenseStatus &&
        this.currentLicensePeriod.LicenseStatus.length > 0
      ) {
        let licenseStatus = this.currentLicensePeriod.LicenseStatus.filter(
          (item) => {
            return true
          }
        )
          .map((item) => {
            return {
              ...item,
              Description: item.Status.Description,
            }
          })
          .sort((a, b) => {
            return b.ID - a.ID
          })
        if (licenseStatus.length > 0) {
          return licenseStatus[0]
        } else {
          return {}
        }
      } else {
        return {}
      }
    },
    validLicense() {
      let validationErrors = []
      if (this.selectedHempLicense && this.selectedHempLicense.License) {
        let leasedAreas = this.selectedHempLicense.HempLicenseArea.filter(
          (i) => i.HempOwnershipID == 1 && i.HempLicenseDocument.length == 0
        )
        if (this.selectedHempLicense.KeyParticipants.length == 0) {
          validationErrors.push('At least 1 key participants must be specified')
        }
        if (this.selectedHempLicense.HempLicenseArea.length == 0) {
          validationErrors.push('A license area must be specified')
        }
        if (leasedAreas.length > 0) {
          leasedAreas.forEach((i) => {
            validationErrors.push(
              `Area ${i.Name} requires a landowner agreement`
            )
          })
        }
        if (this.selectedHempLicense.License.LicenseSubType.length == 0) {
          validationErrors.push('One or more license types must be selected')
        }
        if (!this.selectedHempLicense.BusinessTypeID) {
          validationErrors.push('A business type must be selected')
        }
        if (
          this.selectedHempLicense.BusinessTypeID == 5 &&
          !this.selectedHempLicense.Other
        ) {
          validationErrors.push(
            `If the business type "Other" is selected, the type must be specified.`
          )
        }
        if (!this.selectedHempLicense.DateOfBirth) {
          validationErrors.push(`A date of birth must be specified.`)
        }
        let docs = this.selectedHempLicense.HempLicenseDocument.map((i) => {
          return i.Document
        })
        if (docs.findIndex((i) => i.DocumentTypeID == 3) == -1) {
          validationErrors.push('An affidavit must be attached')
        }
        this.validationErrors = validationErrors
        return validationErrors.length == 0
      } else {
        return false
      }
    },
    // currentLicenseStatus() {
    //   let licenseStatus = this.selectedHempLicense.License.map((item) => {
    //     return {
    //       ...item,
    //       Description: item.LicensePeriod.LicenseStatus.Status.Description,
    //     }
    //   }).sort((a, b) => {
    //     return b.ID - a.ID
    //   })
    //   console.log(licenseStatus)
    //   if (licenseStatus.length > 0) {
    //     return licenseStatus[0]
    //   } else {
    //     return {}
    //   }
    // },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('hempLicenseArea', [
      'saveHempLicenseArea',
      'deleteHempLicenseArea',
    ]),
    ...call('keyParticipants', [
      'saveKeyParticipants',
      'deleteKeyParticipants',
    ]),
    ...call('hempLicense', [
      'saveHempLicense',
      'loadSelectedHempLicense',
      'refreshSelectedHempLicense',
      'setSelectedHempLicense',
    ]),
    ...call('licensePeriod', ['saveLicensePeriod']),
    ...call('luLicenseType', ['loadLuLicenseType']),
    ...call('licenseStatus', ['saveLicenseStatus']),
    async validate() {
      this.$refs.hempLicenseForm.validate()
    },
    async validateAreas() {
      this.$refs.hempLicenseAreasForm.validate()
    },
    async validateKeyParticipants() {
      this.$refs.hempKeyParticipantsForm.validate()
    },
    async validateDocs() {
      this.$refs.hempLicenseDocsForm.validate()
    },

    saveHempLicenseData() {
      this.validate().then(() => {
        if (this.valid) {
          if (
            this.selectedSetHempLicense &&
            this.selectedSetHempLicense.License &&
            this.selectedSetHempLicense.License.BusinessID == 0
          ) {
            this.selectedSetHempLicense.License.BusinessID = parseInt(
              this.$route.params.businessId
            )
            this.selectedSetHempLicense.License.LicenseTypeID = 1
          }
          this.saveHempLicense(this.selectedSetHempLicense.getSaveData()).then(
            (res) => {
              if (
                !this.$route.params.hempLicenseID &&
                res.data &&
                res.data.ID
              ) {
                this.$route.params.hempLicenseID = res.data.ID
                this.loadSelectedHempLicense(res.data.ID)
              } else {
                this.refreshSelectedHempLicense()
              }

              this.$refs.hempLicenseForm.resetValidation()
            },
            (err) => {}
          )
          //this.dialog = false
        }
      })
    },
    saveHempLicenseAreas() {
      this.validateAreas().then(() => {
        if (this.validLicenseArea) {
          this.selectedHempLicenseArea.HempLicenseID = parseInt(
            this.selectedHempLicense.ID
          )
          this.saveHempLicenseArea(
            this.selectedHempLicenseArea.removeRelated()
          ).then(
            (res) => {
              this.refreshSelectedHempLicense()
              this.$refs.hempLicenseAreasForm.resetValidation()
            },
            (err) => {}
          )
          this.licensedAreasDialog = false
        }
      })
    },
    saveKeyParticipantsList() {
      this.validateKeyParticipants().then(() => {
        if (this.validKeyParticipants) {
          this.selectedKeyParticipants.HempLicenseID = parseInt(
            this.selectedHempLicense.ID
          )
          this.saveKeyParticipants(
            this.selectedKeyParticipants.removeRelated()
          ).then(
            (res) => {
              this.refreshSelectedHempLicense()
              this.$refs.hempKeyParticipantsForm.resetValidation()
            },
            (err) => {}
          )
          this.licensedKeyParticipantsDialog = false
        }
      })
    },
    saveLicense() {
      if (this.validLicense) {
        var licenseStatus = new LicenseStatus({
          LicensePeriodID: this.currentLicensePeriod.ID,
          StatusID: 1,
        })
        this.saveLicenseStatus(licenseStatus.removeRelated()).then(
          (res) => {
            this.$router.push({
              path: `/userBusiness/${this.selectedHempLicense.License.BusinessID}`,
            })
          },
          (err) => {}
        )
        //this.licensedAreasDialog = false
      }
    },
    saveLicenseStatusUpdate() {
      this.validate().then(() => {
        if (this.validLicenseStatus) {
          var licenseUpdateStatus = new LicenseStatus({
            LicensePeriodID: this.currentLicensePeriod.ID,
            StatusID: this.selectedLicenseStatus.StatusID,
          })
          this.saveLicenseStatus(licenseUpdateStatus.removeRelated()).then(
            (res) => {
              this.refreshSelectedHempLicense()
            },
            (err) => {}
          )
          this.licenseStatusDialog = false
        }
      })
    },
    addLicensedArea() {
      this.modalText = 'Add Licensed Area'
      this.selectedHempLicenseArea = new HempLicenseArea()
      this.licensedAreasDialog = !this.licensedAreasDialog
    },
    addKeyParticipant() {
      this.modalText = 'Add Key Participant'
      this.selectedKeyParticipants = new KeyParticipants()
      this.licensedKeyParticipantsDialog = !this.licensedKeyParticipantsDialog
    },
    editHempLicenseArea(entry) {
      this.selectedHempLicenseArea = new HempLicenseArea(entry)
      this.modalText = 'Edit Licensed Area'
      if (!this.isInternalUser && !this.businessCanEditLicense) {
        this.modalText = 'Licensed Area'
      }
      this.licensedAreasDialog = !this.licensedAreasDialog
    },
    editKeyParticipant(entry) {
      this.selectedKeyParticipants = new KeyParticipants(entry)
      this.modalText = 'Edit Key Participant'
      if (!this.isInternalUser && !this.businessCanEditLicense) {
        this.modalText = 'Key Participant'
      }
      this.licensedKeyParticipantsDialog = !this.licensedKeyParticipantsDialog
    },
    closeDialog() {
      this.licensedAreasDialog = !this.licensedAreasDialog
    },
    closeDialogKeyParticipant() {
      this.licensedKeyParticipantsDialog = !this.licensedKeyParticipantsDialog
    },
    openInstructions() {
      this.dialog = true
    },
    updateLicenseStatus() {
      this.modalText = 'Update License Status'
      this.selectedLicenseStatus = new LicenseStatus()
      this.licenseStatusDialog = !this.licenseStatusDialog
    },
    licenseStatusUpdateOpen() {
      this.licenseStatusDialog = true
    },
    licenseStatusUpdateClose() {
      this.licenseStatusDialog = false
    },
    closeInstructionsDialog() {
      this.dialog = false
    },
    editLicenseFee() {
      this.newLicenseFee = this.currentLicensePeriod.LicenseFee
      this.licenseFeeDialog = true
    },
    saveLicenseFee() {
      let licensePeriod = new LicensePeriod(this.currentLicensePeriod)
      licensePeriod.LicenseFee = this.newLicenseFee
      this.saveLicensePeriod(licensePeriod.removeRelated()).then((res) => {
        this.licenseFeeDialog = false
        this.refreshSelectedHempLicense()
      })
    },
    licenseFeeDialogClose() {
      this.licenseFeeDialog = false
    },
  },
  watch: {
    selectedHempLicense(nval, oval) {
      this.selectedSetHempLicense = new HempLicense(nval)
    },
  },
}
</script>
<style>
.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: white !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0 !important;
}
</style>
