<template>
  <div v-if="selectedHempLicense.ID > 0">
    <v-row v-if="isInternalUser">
      <v-col sm="12">
        <v-card class="mx-auto">
          <v-list-item class="primary">
            <v-list-item-content>
              <v-list-item-title class="headline text-left white--text">
                License Notes
                <v-btn
                  color="white"
                  class="primary--text ml-4"
                  small
                  @click="addLicenseNotes"
                  >Add Notes</v-btn
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-card-text class="headline">
            <v-row>
              <v-col sm="12" v-if="selectedHempLicense.ID > 0">
                <v-data-table
                  :headers="filteredHeaders"
                  :items="selectedHempLicense.License.LicenseNotes"
                  :search="searchText"
                  class="pt-4"
                  :loading="loading"
                  dense
                >
                  <template v-slot:item.edit="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          color="primary"
                          v-on="on"
                          icon
                          @click="editLicenseNotes(item)"
                        >
                          <v-icon class="clickable" color="primary"
                            >mdi-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit LicenseNotes Information</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.InsertDate="{ item }">
                    {{ item.InsertDate | dateformat }}
                  </template>
                  <template v-slot:item.UpdateDate="{ item }">
                    {{ item.UpdateDate | dateformat }}
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <v-tooltip v-if="item.ID > 0" bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          color="primary"
                          v-on="on"
                          icon
                          @click="deleteConfirm(item)"
                        >
                          <v-icon class="clickable" color="primary"
                            >mdi-delete</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Delete LicenseNotes</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-dialog persistent v-model="licenseNoteDialog" width="750">
              <v-card class="elevation-0">
                <v-card-title class="primary">
                  <h3 class="primary headline text-left white--text">
                    {{ modalText }}
                  </h3>
                </v-card-title>
                <v-card-text class="headline">
                  <v-row>
                    <v-col sm="12">
                      <v-form
                        ref="licenseNotesForm"
                        @submit.prevent
                        lazy-validation
                        v-model="valid"
                      >
                        <v-row>
                          <v-col sm="12">
                            <v-textarea
                              label="Note"
                              v-model="selectedLicenseNotes.Note"
                              oulined
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-btn color="primary" @click="saveLicenseNotesDetails"
                      >Save</v-btn
                    >
                    <v-btn color="primary" @click="closeDialog">Close</v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
import LicenseNotes from '@classes/LicenseNotes'
import { authMethods, authComputed } from '@state/helpers'
export default {
  page: {
    title: 'LicenseNotes',
  },
  props: {
    currentUserRole: Boolean,
  },
  data() {
    return {
      valid: true,
      licenseNoteDialog: false,
      modalText: '',
      searchText: '',
      panel: [0, 1],
      selectedLicenseNotes: new LicenseNotes(),
    }
  },
  created() {},
  computed: {
    ...authComputed,
    ...get('hempLicense', ['selectedHempLicense', 'loading']),
    headers() {
      return [
        {
          text: '',
          align: 'center',
          value: 'edit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Note',
          align: 'center',
          sortable: true,
          value: 'Note',
          visible: true,
        },
        {
          text: 'Insert Date',
          align: 'center',
          sortable: true,
          value: 'InsertDate',
          visible: true,
        },
        {
          text: 'Update Date',
          align: 'center',
          sortable: true,
          value: 'UpdateDate',
          visible: true,
        },
        //{
        //  text: 'Insert By',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'InsertBy.Description',
        //  visible: true,
        //},
        //{
        //  text: 'License',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'License.Description',
        //  visible: true,
        //},
        //{
        //  text: 'Update By',
        //  align: 'center',
        //  sortable: true,
        // //templateFIXME: this is just a guess, and may need changed
        //  value: 'UpdateBy.Description',
        //  visible: true,
        //},
        {
          text: '',
          align: 'center',
          value: 'delete',
          visible: false,
          sortable: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible)
    },
  },
  methods: {
    handleError: call('errors/handleError'),
    ...call('licenseNotes', [
      'saveLicenseNotes',
      'deleteLicenseNotes',
      'loadLicenseNotes',
      'clearLookupCacheAndReloadLicenseNotes',
    ]),
    ...call('hempLicense', ['refreshSelectedHempLicense']),
    async validate() {
      this.$refs.licenseNotesForm.validate()
    },

    editLicenseNotes(entry) {
      this.selectedLicenseNotes = new LicenseNotes(entry)
      this.modalText = 'Edit LicenseNotes'
      this.licenseNoteDialog = !this.licenseNoteDialog
    },
    addLicenseNotes() {
      this.modalText = 'Add Note'
      this.selectedLicenseNotes = new LicenseNotes({
        LicenseID: this.selectedHempLicense.LicenseID,
      })
      this.licenseNoteDialog = !this.licenseNoteDialog
    },
    closeDialog() {
      this.licenseNoteDialog = !this.licenseNoteDialog
    },
    saveLicenseNotesDetails() {
      this.validate().then(() => {
        if (this.valid) {
          this.saveLicenseNotes(this.selectedLicenseNotes.removeRelated()).then(
            (res) => {
              this.refreshSelectedHempLicense()
              this.$refs.licenseNotesForm.resetValidation()
            },
            (err) => {}
          )
          this.licenseNoteDialog = false
        }
      })
    },
  },
  watch: {},
}
</script>
<style scoped></style>
