<template>
  <div>
    <v-card class="mx-auto">
      <v-list-item class="primary">
        <v-list-item-content>
          <v-list-item-title class="headline text-left white--text"
            >Application Checklist</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
      <v-card-text class="headline">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              Application Instructions
              <br />
              <span class="subtitle-1"
                >Please complete all fields and ensure that all items listed
                below are added as an attachment to the application. Any
                incomplete applications will be rejected and returned to the
                applicant. Once the application has been submitted, please mail
                the $750.00 non-refundable license application fee ($500.00 for
                an educational or non-profit organization) to Wyoming Department
                of Agriculture, ATTN: Hemp Licensing, 2219 Carey Ave, Cheyenne,
                WY 82002. Please reference "Business Name, Hemp Licensing Fee"
                in the memo line.</span
              >
              <br /><br />
              Required Attachments:
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Acknowledgement
                <a href="acknowledgement.pdf" target="_blank"
                  >Form</a
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Landowner
                <a href="landowner_agreement.pdf" target="_blank">Agreement</a>
                (if applicable)</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Photo Identification</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Maps of Licensed Area(s) (FSA, Google, Bing,
                etc.)</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Criminal History Report for applicant, or if the applicant is a
                business entity, each Key Participant</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >(Business Applicants ONLY) Wyoming Secretary of State
                Certificate of Good Standing</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >(Processors ONLY) Description of all hemp products to be used
                and Statement of Intended Use for all hemp materials
                received</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >License application fee--to be mailed to the
                department</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <span class="font-weight-black caption"
                >Approved hemp licenses will be valid for the remainder of the
                current calendar year. All licenses will expire on December 31
                of each year.</span
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions v-if="closeInstructions">
        <v-btn small color="primary" @click="$emit('closeInstructionsDialog')"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  page: {
    title: 'ApplicationInformation',
  },
  model: {
    prop: 'dialog',
    event: 'closeInstructionsDialog',
  },
  props: {
    closeInstructions: false,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
